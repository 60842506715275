@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
  }
  body {
    @apply font-montserrat text-[#293845] selection:bg-[rgba(223,60,177,0.25)] selection:text-white;
  }
}

@layer utilities {
  input:focus + .search,
  input:not(:placeholder-shown) + .search {
    @apply hidden;
  }

  /* .videomockup {
    width: 215px;
    height: 370px;
    position: relative;
  }
  .videomockup:after {
    content: '';
    display: block;
    background: url('images/iPhone-13-Pro-Front.png') no-repeat top left
      transparent;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    z-index: 10;
  }
  .videomockup video {
    position: absolute;
    top: 24px;
    left: 66px;
    z-index: 5;
  } */
}
